<template>
  <div :class="$style.menuWrapper">
    <div :class="$style.menu">
      <template v-for="e in accessMenu">
        <router-link
          :key="e.title"
          v-if="e.condition"
          :class="$style.item"
          :to="e.link"
          exact
        >
          <h1>{{ e.title }}</h1>
        </router-link>
      </template>
      <router-link
        :class="$style.item"
        :to="$options.PROFILE_ROUTES.INDEX"
        exact
      >
        <h1>Профиль</h1>
      </router-link>
      <div :class="$style.item" @click="$store.dispatch('logout')">
        <h1>Выход</h1>
      </div>
    </div>
    <transition name="page">
      <router-view />
    </transition>
  </div>
</template>

<script>
import {
  ADDEVENT_ADMIN_ROUTES,
  ADDSELLERS_ADMIN_ROUTES,
  ADDWINE_ADMIN_ROUTES,
  LANDING_ROUTES,
  CHATS_ROUTES,
  MANAGERS_ROUTES,
  PROFILE_ROUTES,
} from '@/constants/routing'
import { mapGetters } from 'vuex'

export default {
  ADDEVENT_ADMIN_ROUTES,
  ADDSELLERS_ADMIN_ROUTES,
  ADDWINE_ADMIN_ROUTES,
  LANDING_ROUTES,
  CHATS_ROUTES,
  MANAGERS_ROUTES,
  PROFILE_ROUTES,
  computed: {
    ...mapGetters({
      filterAccessMenuList: 'filterAccessMenuList',
    }),
    accessMenu() {
      return this.filterAccessMenuList(this.menu)
    },
    menu() {
      return [
        {
          condition: this.$store.getters.landingsAccess,
          link: LANDING_ROUTES.LIST,
          title: 'AddLandings',
        },
        {
          condition: this.$store.getters.sellersAccess,
          link: ADDSELLERS_ADMIN_ROUTES.INDEX,
          title: 'AddSellers',
        },
        {
          condition: this.$store.getters.addwineAccess,
          link: ADDWINE_ADMIN_ROUTES.INDEX,
          title: 'Addwine',
        },
        {
          condition: true,
          link: ADDEVENT_ADMIN_ROUTES.INDEX,
          title: 'Addevent',
        },
        {
          condition: this.$store.getters.isOwner,
          link: MANAGERS_ROUTES.INDEX,
          title: 'Менеджеры',
        },
        {
          condition: this.$store.getters.coordinatorAccess,
          link: CHATS_ROUTES.CHATS_INDEX,
          title: 'Чаты координатора',
        },
      ]
    },
  },
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors';

.menuWrapper {
  margin: 0 auto;
  .menu {
    max-width: 60rem;
    min-height: 80vh;
    margin: 4rem auto 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .item {
      width: 40%;
      min-height: 25%;
      margin: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      cursor: pointer;
      border-radius: 0.375rem;
      background: $bright-gold;
      box-shadow: 0px 0.625rem 0.625rem rgba(0, 0, 0, 0.15);
      &:hover {
        h1 {
          font-size: 2rem;
        }
      }
    }
  }
}
</style>
